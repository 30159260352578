@import 'variables';
@import 'mixin';
@import 'sider';
@import 'gradient';
@import 'redoc';

body {
  font-family: 'Graphik-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji' !important;
	-webkit-font-smoothing: antialiased;
	-webkit-font-feature-settings: "tnum", "tnum";
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	font-feature-settings: "tnum", "tnum";
	margin: 0 !important;
}

#root,
body,
html {
	height: 100%;
}

.card{
  &__docs, &__icon-center{
    color: $primary;
    p,a{
    color: $primary !important;
    }

    .cuboh-card-actions{
      border-top:0;
    }

  }

  &__icon-center {
      position: relative;
      top: 5px;
      right: 3px;
  }

}

.apps{
  margin-top:30px;
}

table a {
	color: $secondary !important;
}

.cuboh-layout {
	min-height: 100% !important;
}

.main-content {
	margin: 16px 24px 0;
}

.cuboh-layout-sider-trigger {
	display: none;
}

.content-wrapper {
	margin: 24px;
	padding-top: 0;

	.child-content-wrapper {
		margin: -24px -24px 0;
		padding: 24px;
		height: 100%;
	}
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.sider-menu-logo {
	position: relative;
	padding: 0 24px;
	overflow: hidden;
	background: $primary;
	cursor: pointer;
	transition: all .3s;

	img {
		display: inline-block;
		height: 32px;
		vertical-align: middle;
	}

	h1 {
		display: inline-block;
		margin: 0 0 0 12px;
		color: #fff;
		font-weight: 600;
		font-size: 20px;
		vertical-align: middle;
		animation: fade-in;
		animation-duration: .3s;
	}

	a {
		display: flex;
		align-items: center;
		height: $layout-header-height;
	}
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color .3s;
}

.trigger:hover {
	color: #1890ff;
}

.cuboh-tabs-tab-active {
	background-color: #e6f7ff;
}

.cuboh-menu-item-selected {
	a {
		color: white;
	}
}

.cuboh-menu-item {
	a {
		color: rgba(255, 255, 255, 0.65);

		&:hover {
			color: white;
		}

		&:focus {
			text-decoration: none;
			color: white;
		}
	}
}

.cuboh-menu-submenu-open {
	span {
		color: white;
	}
}

.app-avatar {
  margin:3px;
  -webkit-box-shadow: 0 0 3px 30x rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
}
