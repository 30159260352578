.menu-content{
  img{
    cursor:pointer;
    padding: 20px;
  }
}

span.post,span.get,span.patch {
  color: $primary;
}


.string-property, .property{
    color: $info !important;
    font-size: 13px;
    font-weight: 600;
}

.string {
  color: $venmo;
  font-size: 13px;
  font-weight: 600;
}

[data-item-id|="group/Direct-API"] > label:first-child, [data-item-id|="group/Direct-API"] + [data-item-id|="group/Models-and-Definitions"] > label:first-child {
  background-color: #9e8ab8;
  color: #fff;
  opacity: 1;
  padding: 12.5px 20px !important;

  &:hover{
    background-color: #9e8ab8;
  }
}

[data-item-id|="group/Connect-API"]>label:first-child, [data-item-id|="group/Connect-API"] +
[data-item-id|="group/Models-and-Definitions"]>label:first-child {
  background-color: #89ad95;
  color: #fff;
  opacity: 1;

  padding: 12.5px 20px !important;

  &:hover{
    background-color: #89ad95;
  }
}

[role|="menu"] {
  .active{
    background-color: $secondary;

  }

  label {
    &:hover {
        background-color: $secondary;
      }
  }
}

[role|="menuitem"] {
  font-size:14px !important;
}

[role|="menuitem"].active + ul {
  label {
    background-color: #000c17;

    &:hover{
      background-color: $secondary;
    }
  }
}

[role|="menu"] + div {
  display:none;
}
