.index {
    &__container {
        height: 100%;
    }

    &__footer {
        text-align: center;

        &--light {
            background: $white !important;
        }

        &--dark {
            background: $primary !important;
        }
    }
}

.portal {
  z-index:1000;
}

.cuboh-spin-container,
.cuboh-spin-nested-loading {
    height: 100%;
}

.cuboh-form {
    &__password {
        margin-bottom: 16px;
    }
}

.cuboh-input-affix-wrapper {
    color: $placeholder;

    &-focused {
        .cuboh-input-prefix {
            color: rgba(0, 0, 0, 0.85);
        }
    }
}

.index-wrapper {
    height: 100%;
    background: rgb(45, 43, 194);
    background: linear-gradient(315deg,
            rgba(45, 43, 194, 1) 0%,
            rgba(43, 48, 194, 1) 35%,
            rgba(0, 112, 255, 1) 100%);

    .ant-row {
        height: 100%;
    }
}

.index-container {
    padding: 20px 50px 60px 50px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
        0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);

    button {
        width: 100%;
    }

    a{
      white-space: pre-wrap;
    }

    p {
        color: rgb(204, 204, 204);
        text-align: center;
        margin-top: 16px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        text-align: center;
        cursor: pointer;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 130px;
        }

        span {
            vertical-align: text-bottom;
            font-size: 16px;
            text-transform: uppercase;
            display: inline-block;
            font-weight: 700;
        }
    }
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}


//Hex needed here for js function that normalize's colors.  Scss variable doesnt work *shrug*
#gradient-canvas {
    width: 100%;
    height: 100%;
    --gradient-color-1: #89ad95;
    --gradient-color-2: #4845df;
    --gradient-color-3: #a4c2d8;
    --gradient-color-4: #d1adff;
    z-index: 0;
    border:0;
}

.container {
    canvas {
        position: absolute;
        border: 1px solid black;
    }
}

.cuboh-spin-container,
.cuboh-spin-nested-loading {
    height: 100%;
}

.cuboh-form {
    &__password {
        margin-bottom: 16px;
    }
}

.cuboh-input-affix-wrapper {
    color: $placeholder;

    &-focused {
        .cuboh-input-prefix {
            color: rgba(0, 0, 0, 0.85);
        }
    }
}
